<template>
  <section
    class="
      w-full
      bg-white
      rounded-xl   p-6
      justify-start
      shadow-modal
      animate-pulse
    "
  >

    <div class="grid grid-cols-1 mdlg:grid-cols-[1fr,200px,200px] justify-between items-center gap-4 mdlg:gap-6" >
        <span class=" w-50 h-4 mdlg:h-6 bg-wrh-gray-extralight mb-2 mdlg:col-start-1 mdlg:col-end-4"></span>
        <div class=" block w-22 h-12 bg-wrh-gray-extralight mdlg:col-start-2 mdlg:col-end-3 mdlg:row-start-2 mdlg:row-end-3 mdlg:self-start mdlg:justify-self-center"></div>
        <p class="w-full mdlg:col-start-1 mdlg:col-end-2 mdlg:row-start-2 mdlg:row-end-3">
            <span class="block  bg-wrh-gray-extralight w-full h-3"></span>
            <span class="block bg-wrh-gray-extralight  w-full h-3 mt-2"></span>
        </p>
       <button class="
       mdlg:row-start-2 mdlg:row-end-3
       mdlg:col-start-3 mdlg:col-end-4
       bg-wrh-gray-extralight
       w-full
       h-9
        "></button>
    </div>
  </section>
</template>
