<template>
  <div class="w-full block  rounded-xl bg-white shadow-modal animate-pulse p-4 pb-5 lg:p-6 lg:pb-8 relative">
      <div class="w-full flex flex flex-col mdlg:items-center mdlg:flex-row justify-between">
      <span class="w-50 h-4 mdlg:h-6 bg-wrh-gray-extralight mb-2"></span>
      <span class="w-20 h-4 bg-wrh-gray-extralight mt-2"></span>
      </div>
      <!--Progressbar TimeLine-->
    <div class="w-full flex items-center justify-center space-x-4 lg:space-x-20 mt-10">
      <ul class="grid grid-cols-5 grid-rows-1 pl-0 -mx-6 mdlg:mx-0">
        <li class="flex flex-col items-center space-y-4 ">
          <span class=" w-5 h-5 mdlg:w-10 mdlg:h-10 bg-wrh-gray-extralight"></span>
          <div class="flex items-center justify-center">
            <span class="-mt-px flex-grow h-0.5 bg-transparent w-8  mdlg:w-14 lg:w-[4.5rem]" aria-hidden="true"></span>
            <span class="rounded-full w-2 h-2 mdlg:w-3 mdlg:h-3 bg-wrh-gray-extralight"></span>
            <span class="-mt-px flex-grow h-0.5 w-8 bg-wrh-gray-extralight mdlg:w-14 lg:w-[4.5rem]" aria-hidden="true"></span>
          </div>
          <span class="w-20 h-5 bg-wrh-gray-extralight hidden mdlg:inline"></span>
        </li>

        <li class=" flex flex-col items-center space-y-4">
         <span class=" w-5 h-5 mdlg:w-10 mdlg:h-10 bg-wrh-gray-extralight"></span>
          <div class="flex items-center justify-center">
            <span class="-mt-px flex-grow h-0.5 bg-wrh-gray-extralight w-8  mdlg:w-14 lg:w-[4.5rem]" aria-hidden="true"></span>
            <span class="rounded-full w-2 h-2 mdlg:w-3 mdlg:h-3  bg-wrh-gray-extralight"></span>
            <span class="-mt-px flex-grow h-0.5 bg-wrh-gray-extralight w-8  mdlg:w-14 lg:w-[4.5rem]" aria-hidden="true"></span>
          </div>
           <span class="w-20 h-5 bg-wrh-gray-extralight hidden mdlg:inline"></span>
        </li>

         <li class="flex flex-col items-center space-y-4">
          <span class=" w-5 h-5 mdlg:w-10 mdlg:h-10 bg-wrh-gray-extralight"></span>
          <div class="flex items-center justify-center">
            <span class="-mt-px flex-grow h-0.5 bg-wrh-gray-extralight w-8  mdlg:w-14 lg:w-[4.5rem]" aria-hidden="true"></span>
            <span class="rounded-full w-2 h-2 mdlg:w-3 mdlg:h-3 bg-wrh-gray-extralight"></span>
            <span class="-mt-px flex-grow h-0.5 bg-wrh-gray-extralight w-8  mdlg:w-14 lg:w-[4.5rem]" aria-hidden="true"></span>
          </div>
        <span class="w-20 h-5 bg-wrh-gray-extralight hidden mdlg:inline"></span>
        </li>

        <li class="flex flex-col items-center space-y-4">
          <span class=" w-5 h-5 mdlg:w-10 mdlg:h-10 bg-wrh-gray-extralight"></span>
          <div class="flex items-center justify-center">
            <span class="-mt-px flex-grow h-0.5 bg-wrh-gray-extralight w-8  mdlg:w-14 lg:w-[4.5rem]" aria-hidden="true"></span>
            <span class="rounded-full w-2 h-2 mdlg:w-3 mdlg:h-3 bg-wrh-gray-extralight"></span>
            <span class="-mt-px flex-grow h-0.5 bg-wrh-gray-extralight w-8  mdlg:w-14 lg:w-[4.5rem]" aria-hidden="true"></span>
          </div>
           <span class="w-20 h-5 bg-wrh-gray-extralight hidden mdlg:inline"></span>
        </li>

        <li class="flex flex-col items-center space-y-4">
         <span class=" w-5 h-5 mdlg:w-10 mdlg:h-10 bg-wrh-gray-extralight"></span>
          <div class="flex items-center justify-center">
            <span class="-mt-px flex-grow h-0.5 bg-wrh-gray-extralight w-8  mdlg:w-14 lg:w-[4.5rem]" aria-hidden="true"></span>
            <span class="rounded-full w-2 h-2 mdlg:w-3 mdlg:h-3 bg-wrh-gray-extralight"></span>
            <span class="-mt-px flex-grow h-0.5 bg-transparent w-8  mdlg:w-14 lg:w-[4.5rem]" aria-hidden="true"></span>
          </div>
          <span class="w-20 h-5 bg-wrh-gray-extralight hidden mdlg:inline"></span>
        </li>
      </ul>
      <div class="flex flex-col items-center justify-center  mdlg:relative absolute top-4 mdlg:top-0 mdlg:right-0 right-2">
        <span class="w-28 h-14  bg-wrh-gray-extralight"></span>
        <span class="w-28 h-6 mt-2  bg-wrh-gray-extralight  hidden mdlg:inline"></span>
      </div>
    </div>
    <div class="mt-6 mdlg:hidden">
      <span class="w-20 h-4 bg-wrh-gray-extralight hidden mdlg:inline"></span>
    </div>
  </div>
</template>
